import React, { useState } from 'react';
import { bool, func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import Field, { hasDataInFields } from '../../Field';
import { Heading } from '../../../../components';
import { FormattedMessage } from '../../../../util/reactIntl';
import { isMainSearchTypeKeywords, isOriginInUse } from '../../../../util/search';
import TopbarSearchForm from '../../../../containers/TopbarContainer/Topbar/TopbarSearchForm/TopbarSearchForm';
import { parse, stringify } from '../../../../util/urlHelpers';
import { createResourceLocatorString, pathByRouteName } from '../../../../util/routes';
import SectionContainer from '../SectionContainer';
import css from './SectionHero.module.css';

// Section component for a website's hero section
// The Section Hero doesn't have any Blocks by default, all the configurations are made in the Section Hero settings
const SectionHero = props => {
  const {
    sectionId,
    className,
    rootClassName,
    defaultClasses,
    title,
    description,
    appearance,
    callToAction,
    options,
    location,
    config,
    intl,
  } = props;

  const [selectedListingType, setSelectedListingType] = useState('events');
  const { mobilemenu, mobilesearch, keywords, address, origin, bounds } = parse(location.search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });


  // If external mapping has been included for fields
  // E.g. { h1: { component: MyAwesomeHeader } }
  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };

  const hasHeaderFields = hasDataInFields([title, description, callToAction], fieldOptions);

  const topbarSearcInitialValues = () => {
    if (isMainSearchTypeKeywords(config)) {
      return { keywords };
    }

    // Only render current search if full place object is available in the URL params
    const locationFieldsPresent = isOriginInUse(config)
      ? address && origin && bounds
      : address && bounds;
    return {
      location: locationFieldsPresent
        ? {
            search: address,
            selectedPlace: { address, origin, bounds },
          }
        : null,
    };
  };
  const initialSearchFormValues = topbarSearcInitialValues();

  const onSearchSubmit = (values) => {
    const { currentSearchParams } = props || {};
    const { history, routeConfiguration } = props;

    const topbarSearchParams = () => {
      const listingType = values?.listingType;
      const listingTypeParam = !!listingType ? {'pub_listingType': listingType } : {};
      // topbar search defaults to 'location' search
      const { search, selectedPlace } = values?.location || {};
      const { origin, bounds } = selectedPlace || {};
      const originMaybe = isOriginInUse(config) && !!origin ? { origin } : {};
      

      return {
        ...originMaybe,
        address: search,
        keywords: values?.keywords,
        bounds,
        ...listingTypeParam,
      };
    };
    const searchParams = {
      ...currentSearchParams,
      ...topbarSearchParams(),
    };
    history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, searchParams));
  }

  const search = (
    <TopbarSearchForm
      className={css.searchLink}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
      appConfig={config}
      onTypeChange={(e) => setSelectedListingType(e)}
      selectedListingType={selectedListingType}
    />
  );
  const typeLabel = selectedListingType === 'services'
    ? intl.formatMessage({ id: 'LandingPage.SectionHero.heading.services' })
    : selectedListingType === 'products'
    ? intl.formatMessage({ id: 'LandingPage.SectionHero.heading.products' })
    : selectedListingType === 'podcast'
    ? intl.formatMessage({ id: 'LandingPage.SectionHero.heading.podcast' })
    : selectedListingType === 'zenscapes'
    ? intl.formatMessage({ id: 'LandingPage.SectionHero.heading.zenscapes' })
    : intl.formatMessage({ id: 'LandingPage.SectionHero.heading.events' }); 
  const zenLabel = intl.formatMessage({ id: 'LandingPage.SectionHero.heading.zen' })
  const type = <span className={css.type}>{typeLabel}</span>
  const zen = <span className={css.zen}>{zenLabel}</span>
  return (
    <SectionContainer
      id={sectionId}
      className={className}
      rootClassName={classNames(rootClassName || css.root)}
      appearance={appearance}
      options={fieldOptions}
    >
      <header className={classNames(css.hero)}>
        <Heading as="h2" rootClassName={css.heading}>
          <span>
            <FormattedMessage id="LandingPage.SectionHero.heading" values={{ zen, type }} />
          </span>
        </Heading>
        {search}
      </header>
    </SectionContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionHero.defaultProps = {
  className: null,
  rootClassName: null,
  defaultClasses: null,
  textClassName: null,
  title: null,
  description: null,
  appearance: null,
  callToAction: null,
  isInsideContainer: false,
  options: null,
};

SectionHero.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  defaultClasses: shape({
    sectionDetails: string,
    title: string,
    description: string,
    ctaButton: string,
  }),
  title: object,
  description: object,
  appearance: object,
  callToAction: object,
  isInsideContainer: bool,
  options: propTypeOption,
};

export default SectionHero;
