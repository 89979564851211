import React, { useEffect } from 'react';
import { arrayOf, func, node, number, object, shape, string } from 'prop-types';
import classNames from 'classnames';
import SectionContainer from '../SectionContainer';
import { IconVerified, IconTrophy, IconSubscription } from '../../../../components';
import css from './SectionHowItWorks.module.css';

const SectionHowItWorks = props => {
  const {
    sectionId,
    className,
    rootClassName,
    intl,
  } = props;
 
  return (
    <SectionContainer
      id={sectionId}
      className={className}
      rootClassName={rootClassName}
    >
      <div className={css.howItWorks}>
        <div className={css.block}>
          <div className={css.icon}><IconVerified /></div>
          <div className={css.header}>{intl.formatMessage({ id: 'LandingPage.SectionHowItWorks.verifiedPages' })}</div>
          <div className={css.description}>{intl.formatMessage({ id: 'LandingPage.SectionHowItWorks.verifiedPagesDescription' })}</div>
        </div>
        <div className={css.block}>
          <div className={css.icon}><IconTrophy /></div>
          <div className={css.header}>{intl.formatMessage({ id: 'LandingPage.SectionHowItWorks.learn' })}</div>
          <div className={css.description}>{intl.formatMessage({ id: 'LandingPage.SectionHowItWorks.learnDescription' })}</div>
        </div>
        <div className={css.block}>
          <div className={css.icon}><IconSubscription /></div>
          <div className={css.header}>{intl.formatMessage({ id: 'LandingPage.SectionHowItWorks.subscription' })}</div>
          <div className={css.description}>{intl.formatMessage({ id: 'LandingPage.SectionHowItWorks.subscriptionDescription' })}</div>
        </div>
      </div>
    </SectionContainer>
  );
};

SectionHowItWorks.defaultProps = {
  className: null,
  rootClassName: null,
};

SectionHowItWorks.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
};

export default SectionHowItWorks;
