import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import SectionContainer from '../SectionContainer';
import { ReviewRating } from '../../../../components';
import Slider from 'react-slick';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';

import css from './SectionReviews.module.css';
import review1Avatar from './images/1.jpg';
import review2Avatar from './images/2.jpg';
import review3Avatar from './images/3.jpg';

const SLIDER_SETTINGS = {
  arrows: false,
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const SectionReviews = props => {
  const { sectionId, className, rootClassName, intl } = props;

  const rootClasses = classNames(rootClassName, css.root);

  const reviews = [
    {
      review: intl.formatMessage({ id: 'LandingPage.SectionReviews.review1.review' }),
      rating: 5,
      reviewBy: intl.formatMessage({ id: 'LandingPage.SectionReviews.review1.reviewBy' }),
      provider: {
        avatar: review1Avatar,
        name: intl.formatMessage({ id: 'LandingPage.SectionReviews.review1.providerName' }),
        bio: intl.formatMessage({ id: 'LandingPage.SectionReviews.review1.providerBio' }),
      },
    },
    {
      review: intl.formatMessage({ id: 'LandingPage.SectionReviews.review2.review' }),
      rating: 5,
      reviewBy: intl.formatMessage({ id: 'LandingPage.SectionReviews.review2.reviewBy' }),
      provider: {
        avatar: review2Avatar,
        name: intl.formatMessage({ id: 'LandingPage.SectionReviews.review2.providerName' }),
        bio: intl.formatMessage({ id: 'LandingPage.SectionReviews.review2.providerBio' }),
      },
    },
    {
      review: intl.formatMessage({ id: 'LandingPage.SectionReviews.review3.review' }),
      rating: 5,
      reviewBy: intl.formatMessage({ id: 'LandingPage.SectionReviews.review3.reviewBy' }),
      provider: {
        avatar: review3Avatar,
        name: intl.formatMessage({ id: 'LandingPage.SectionReviews.review3.providerName' }),
        bio: intl.formatMessage({ id: 'LandingPage.SectionReviews.review3.providerBio' }),
      },
    },
  ];

  const hasReviews = !!reviews && reviews?.length > 0;
  return (
    <SectionContainer id={sectionId} className={className} rootClassName={rootClassName}>
      {hasReviews ? (
        <Slider {...SLIDER_SETTINGS} className={css.slider}>
          {reviews.map(r => (
            <div>
              <div className={css.reviewContainer}>
                <div className={css.reviewWrapper}>
                  <div className={css.review}>{r.review}</div>
                  <div className={css.rating}>
                    <ReviewRating
                      rating={r.rating}
                      className={css.mobileReviewRating}
                      reviewStarClassName={css.reviewRatingStar}
                    />
                  </div>
                  <div className={css.reviewBy}>{r.reviewBy}</div>
                </div>
                <div className={css.provider}>
                  <img className={css.avatarImage} alt={r.provider.name} src={r.provider.avatar} />
                  <div className={css.providerDetails}>
                    <div className={css.header}>{r.provider.name}</div>
                    <div className={css.description}>{r.provider.bio}</div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      ) : null}
    </SectionContainer>
  );
};

SectionReviews.defaultProps = {
  className: null,
  rootClassName: null,
};

SectionReviews.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
};

export default SectionReviews;
